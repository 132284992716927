import {
  PromotionDtoSeriesLimited,
  PromotionDtoSeriesUnlimited,
  PromotionImageShape,
  PromotionSeriesType,
} from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import { etWhite } from "../../shared/colors";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./CardItemPromotion.styled";

export interface CardAbstractCollectableDriver {
  // TODO: remove shouldRenderContextMenu when implement save and share card
  // Currently we have pin and delete card, these are for card's owner only
  shouldRenderContextMenu: boolean;
  isContextMenuActivated: boolean;
  onEllipsisIconClicked(): void;
  onCardOverlayClicked(): void;
  numberOfUons: string;
  topRightNote: string;
  promotionUonImageUrl: string;
  imageShape: PromotionImageShape;
  promotionBgImageUrl: string;
  series: PromotionDtoSeriesUnlimited | PromotionDtoSeriesLimited;
  bottomRightNote: string | null;
  zIndex: number;
}

interface IProps {
  driver: CardAbstractCollectableDriver;
  isUonTransactionCard?: boolean;
  renderBottom(): ReactNode;
  renderContextMenu?: () => ReactNode;
}

export const CardAbstractCollectable = observer(
  function CardAbstractCollectable(props: IProps) {
    const {
      numberOfUons,
      topRightNote,
      promotionUonImageUrl,
      imageShape,
      promotionBgImageUrl,
      series,
      bottomRightNote,
      shouldRenderContextMenu,
      isContextMenuActivated,
      onEllipsisIconClicked,
      onCardOverlayClicked,
    } = props.driver;
    return (
      <>
        {isContextMenuActivated && (
          <sc.ContextMenuOverlay
            data-cy="CardAbstractCollectable-CardOverlay"
            onClick={onCardOverlayClicked}
            zIndex={props.driver.zIndex}
          />
        )}
        <sc.Container zIndex={props.driver.zIndex}>
          {isContextMenuActivated && (
            <sc.CardOverlay
              data-cy="CardAbstractCollectable-CardShadowOverlay"
              onClick={onCardOverlayClicked}
            />
          )}
          {props.renderContextMenu && isContextMenuActivated && (
            <sc.ContextMenuContainer data-cy="CardAbstractCollectable-ContextMenuContainer">
              {props.renderContextMenu()}
            </sc.ContextMenuContainer>
          )}
          <sc.CardContainer data-cy="CardAbstractCollectable-Container">
            {shouldRenderContextMenu && (
              <sc.ContextMenu data-cy="CardAbstractCollectable-OpenContextMenuButton">
                <IconSvg
                  icon="more"
                  pointer
                  width="18px"
                  height="18px"
                  padding="0"
                  color={etWhite}
                  onClick={onEllipsisIconClicked}
                />
              </sc.ContextMenu>
            )}
            <sc.BackgroundImageWrapper>
              <sc.BackgroundImage
                data-cy="CardAbstractCollectable-BackgroundImage"
                bgImage={promotionBgImageUrl}
              />
            </sc.BackgroundImageWrapper>
            <sc.EarthLines
              isUonTransactionCard={!!props.isUonTransactionCard}
              data-cy="CardAbstractCollectable-EarthLines"
            />
            <sc.TopBlock
              bgImage={
                imageShape === PromotionImageShape.SQUARE
                  ? promotionUonImageUrl
                  : undefined
              }
            >
              <sc.SquareMetterContainer data-cy="CardAbstractCollectable-SquareMetter">
                <IconSvg
                  icon="uon-m2-logo"
                  dataCy="CardAbstractCollectable-M2BigFrame"
                  width="280px"
                  height="280px"
                  padding="0"
                  margin="0"
                />
                <sc.UnitOfNatureBlock>
                  <sc.UonNumber>{numberOfUons}</sc.UonNumber>
                  <IconSvg
                    icon="uon-text"
                    dataCy="CardAbstractCollectable-UnitOfNatute"
                    width="49px"
                    height="43px"
                    padding="0"
                    margin="0"
                  />
                </sc.UnitOfNatureBlock>
                {imageShape === PromotionImageShape.ROUND && (
                  <sc.UonPromotionImage
                    data-cy="CardAbstractCollectable-PromotionImage"
                    bgImage={promotionUonImageUrl}
                  />
                )}
                <sc.TopRightBlock>
                  <sc.IconWrapper>
                    <IconSvg
                      icon="star"
                      dataCy="CardAbstractCollectable-StarIcon"
                      width="18px"
                      height="18px"
                      padding="0"
                      margin="0"
                    />
                  </sc.IconWrapper>
                  <sc.TopRightText
                    data-cy="CardAbstractCollectable-TopRightNote"
                    role="textbox"
                  >
                    {topRightNote}
                  </sc.TopRightText>
                </sc.TopRightBlock>
                {series.type === PromotionSeriesType.LIMITED && (
                  <sc.Series>{bottomRightNote}</sc.Series>
                )}
              </sc.SquareMetterContainer>
            </sc.TopBlock>
            <sc.BottomBlock>{props.renderBottom()}</sc.BottomBlock>
          </sc.CardContainer>
        </sc.Container>
      </>
    );
  },
);
